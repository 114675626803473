<template>
  <div>
    <!-- <pre>{{ parentdetails }}</pre> -->
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">Logs</h5>
            </v-expansion-panel-header>

            <v-divider class="my-0"></v-divider>

            <v-expansion-panel-content class="px-0 py-0">
              <v-simple-table
                fixed-header
                style="height: calc(100vh - 280px)"
                class="bt-table product-simple-table"
              >
                <template v-slot:default>
                  <!-- table titles -->
                  <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
                    <tr>
                      <th
                        v-for="(supplier_h, index) in supplierTableHeaders"
                        :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                        :style="{ maxWidth: `${supplier_h.width}px` }"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-on="on" v-bind="attrs">
                              <template v-if="supplier_h.heading === 'primary_sales_price'">
                                <v-icon color="blue">mdi-circle</v-icon>
                              </template>
                              <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                                <v-icon color="red">mdi-circle</v-icon>
                              </template>
                              <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                            </div>
                          </template>
                          <span class="h5 text-center">{{ supplier_h.title }}</span>
                        </v-tooltip>
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="!pageLoading">
                    <template v-if="mod_Suppliers.length">
                      <tr
                        :class="index % 1 === 0 && '#fff'"
                        v-for="(row, index) in parentdetails.logs"
                        class="product-listing-tr"
                        :key="'k_' + index"
                      >
                      <!-- {{ supplierTableHeaders }} -->
                        <td
                          style="border-right: none !important"
                          :width="data_headings.width"
                          v-for="(data_headings, idx) in supplierTableHeaders"
                          :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                          class="px-2"
                        >
                          <div
                            class="d-flex justify-center align-center"
                            v-if="data_headings.heading === 'supplier_status'"
                          >
                            <v-switch
                              color="green"
                              :value="false"
                              :ripple="false"
                              hide-details
                              inset
                              disabled="true"
                              class="mt-0"
                              v-model="row[data_headings.heading]"
                            ></v-switch>
                          </div>
                          <div
                            class="d-flex justify-center align-center"
                            v-if="data_headings.heading === 'added_at'"
                          >
                           {{ formatedateTimes(row[data_headings.heading]) }}
                          </div>
                          <div
                            class="d-flex justify-center align-center"
                            v-else-if="
                              data_headings.heading === 'primary_sales_price' ||
                              data_headings.heading === 'secondary_purchase_price'
                            "
                          >
                            {{ formatMoneyVal(row[data_headings.heading]) }}
                          </div>
                          <p v-else class="mb-0 h5 px-6">
                            {{ row[data_headings.heading] }}
                            <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                              / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                            </span>
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatMoney } from "accounting-js";
import moment from "moment-timezone";

export default {
  name: "product-chart",
  title: "Chart Product",
  panel: [0],
  props: {
    parentdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      supplierTableHeaders: [
        { title: "Description", heading: "description", width: 480 },

        { title: "Created Time", heading: "added_at", width: 150 },
      ],
      product: {
        suppliers: [
          {
            id: 1,
            supplier_name: "Nestlé Global. ()",
            supplier_part: "Part",
            supplier_status: "Active",
            primary_sales_price: "48.00",
            secondary_purchase_price: "30.00",
            primary_uom: "CAN",
            secondary_uom: "CTN",
          },
        ],
      },
    };
  },
  methods: {
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formatMoneyVal(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
  computed: {
    mod_Suppliers() {
      return this.product.suppliers;
    },
  },
  mounted() {
    console.log("Router => ", this.$router);
  },
};
</script>

<style scoped>
.th {
  font-size: 10px !important;
}

.product-row {
  margin: 0 !important;
}
</style>
