<template>
  <v-sheet>
    <Dialog :dialog="recordPaymentDialog" card-text-class="px-4 py-0" :dialogWidth="1200">
      <template v-slot:title>Record Primary Payment<v-spacer></v-spacer></template>
      <template v-slot:body>
        <v-container>
          
          <v-row>
            <v-col md="12" class="py-1">
              <!-- <div class="custom-header-blue-text text-h5 text-center mb-2">Record Payment</div> -->
              <table class="table table-sm mb-0" width="100%">
                <thead class="table-light">
                  <tr>
                    <th>PO #</th>
                    <th class="text-center">Vendor</th>

                    <th class="text-end">Amount (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {{
                    getAmount()
                  }}
                  <tr v-for="(row, index) in record" :key="index" class="py-2">
                    <td>
                      <v-chip outlined label small color="primary">{{ row.barcode }}</v-chip>
                    </td>
                    <td align="center" class="text-capitalize">{{ row.display_name }}</td>
                    <td align="end">{{ formatMoney(row.total) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" align="end">
                      <span class="text-h6">Total: {{ formatMoney(getTotals()) }}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </v-col>
          </v-row>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-col md="6" class="py-1">
                  <label for="payment-made" class="field-label required"
                    >Primary Payment Made (RM)</label
                  >
                  <QuantityInput
                    hide-details
                    disabled
                    :loading="pageLoading"
                    id="payment-made"
                    placeholder="Payment Made"
                    v-model="paymentRecordData.payment"
                    class="mb-2"
                    prefixText="RM"
                  >
                  </QuantityInput>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-number" class="field-label required">Payment</label>
                  <TextInput
                    hide-details
                    disabled
                    :loading="pageLoading"
                    id="payment-number"
                    placeholder="Payment Number"
                    v-model="paymentRecordData.payment_number"
                    class="mb-2"
                  >
                  </TextInput>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-date" class="field-label required mb-1">Payment Date</label>
                  <DatePicker
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="payment-date"
                    v-model="paymentRecordData.payment_date"
                  ></DatePicker>
                </v-col>
                <v-col md="6" class="py-1">
                  <label for="payment-mode" class="field-label required"
                    >Primary Payment Mode</label
                  >
                  <SelectInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items.sync="paymentModeList"
                    id="payment-mode"
                    v-model="paymentRecordData.payment_mode"
                    placeholder="Payment Mode"
                  >
                  </SelectInput>
                </v-col>
                <!-- <v-col md="6" class="py-1">
                  <label for="payment-through" class="field-label required">Paid Through</label>
                  <SelectInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items.sync="paymentThroughList"
                    id="payment-through"
                    v-model="item.payment_through"
                  >
                  </SelectInput>
                </v-col> -->
                <v-col md="6" class="py-1">
                  <label for="reference" class="field-label">Reference#</label>
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="reference"
                    placeholder="Reference"
                    v-model="paymentRecordData.reference"
                    class="mb-2"
                  >
                  </TextInput>
                </v-col>
                <v-col md="12" class="py-1">
                  <label for="notes" class="field-label">Notes</label>
                  <TextAreaInput
                    v-model="paymentRecordData.payment_notes"
                    auto-grow
                    :disabled="pageLoading"
                    v-on:keypress="(e) => manageLimit(e)"
                            v-on:paste="(e) => onPaste(e)"
                    :loading="pageLoading"
                    id="notes"
                    placeholder=""
                  ></TextAreaInput>
                </v-col>
                <!-- <v-col md="12" class="py-1">
                  <label for="attachments" class="field-label">Attachments</label>
                 
                  <PurchaseDocument
                    id="file"
                    :directionColumn="false"
                    v-on:uploadData="test($event)"
                    allowAddMore
                    v-model="paymentRecordData.attachments"
                  ></PurchaseDocument>
                </v-col> -->
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="saveRecord()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
//import FileUpload from "@/view/components/FileUpload";
import { formatMoney } from "accounting-js";
import { CREATE_RECORD, GET_PAYMENT } from "@/core/lib/pos.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import QuantityInput from "@/view/components/QuantityInput";

import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectInput from "@/view/components/SelectInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
export default {
  name: "item-dialog",
  title: "Dialog Item",

  data() {
    return {
      className:null,
      excelLoading: null,
      recordPaymentDialog: false,
      paymentThroughList: [
        { text: "Petty Cash", value: 1 },
        { text: "Undeposited Founds", value: 2 },
      ],
      paymentModeList: [
        { text: "Cash", value: 1 },
        { text: "Credit Card Citi Bank", value: 2 },
        { text: "Credit Card C I M B", value: 3 },
        { text: "Credit Card Public Bank", value: 4 },
        { text: "Boss", value: 4 },
      ],
      paymentRecordData: {
        payment_made: null,
        payment_number: null,
        payment_date: null,
        payment_mode: null,
        payment_through: null,
        reference: null,
        payment_notes: null,
        attachments: [],
      },
      paymentRecord: [
        {
          po_number: "PO0001",
          paymentDate: "25/10/2023",
          venderName: "business thrust techsoft pvt ltd",
          amount: 309.88,
        },
      ],
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    manageLimit(e) {
                if (this.paymentRecordData.payment_notes &&
                    this.paymentRecordData.payment_notes.length > 249) {
                    e.preventDefault();
                }  

        },
        onPaste(e) {
            let coppied = e.clipboardData.getData("Text");
            let preVal =  this.paymentRecordData.payment_notes;
            let finalval = String(preVal)+ String(coppied);
            if (Number(finalval.length) > 249) {
                let trimValue = finalval.substring(0, 250);
                    this.paymentRecordData.payment_notes =trimValue;
                    e.preventDefault();
            }
        },
    async getPayment() {
      const data = await GET_PAYMENT();
      this.paymentModeList1 = data;
      const paymentModeList2 = this.paymentModeList1.map((item) => {
        return {
          text: item.payment_type,
          value: item.value,
        };
      });
      this.paymentModeList = paymentModeList2;
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    async saveRecord() {
      if (
          this.paymentRecordData  &&
          !this.paymentRecordData?.payment_date
        ) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The payment date field is required" },
          ]);
          return false;
        }
        if (
          this.paymentRecordData  &&
          !this.paymentRecordData?.payment_mode
        ) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The payment mode field is required" },
          ]);
          return false;
        }
      this.paymentRecordData.record = this.record;

      try {
        const customer = await CREATE_RECORD(this.paymentRecordData);
        this.$router.replace({
          name: "customer-detail",
          params: { id: customer.id },
          query: { t: new Date().getTime() },
        });
        this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Primary Record Payment  Created Successfully." },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    getAmount() {
      if (this.pdetails.total) {
        this.records = [this.pdetails];
        const record1 = this.records.map((item) => {
          return {
            barcode: item.barcode,
            total: item.total,
            display_name: item.supplier?.display_name,
          };
        });
        this.record = record1;
      } else {
        this.records = this.bulkdata;

        const record1 = this.records.map((item) => {
          return {
            barcode: item.barcode,
            total: item.total,
            display_name: item.supplier?.display_name,
          };
        });
        this.record = record1;
      }

      this.paymentRecordData.payment_number = this.record.length;
    },

    getTotals() {
      this.product = this.record;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.total);
      });

      this.paymentRecordData.payment = Number(sum);
      this.paymentRecordData.payment_made = Number(sum);

      return this.paymentRecordData.payment;
    },
  },
  watch: {
    dialog(params) {
      this.recordPaymentDialog = params;
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
    bulkdata: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  components: {
    Dialog,
    //FileUpload,
    TextInput,
    DatePicker,
    TextAreaInput,
    SelectInput,
    // PurchaseDocument,
    QuantityInput,
  },
};
</script>
<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}
.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
