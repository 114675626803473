<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row class="justify-space-between">
      <!-- {{ this.$route.query.page }} -->
      <!-- <pre>{{ payment_items[0].parent_id.code }} {{ payment_items[0].parent_id.barcode }}</pre> -->
      <!-- <v-col md="12" class="header-title py-0"></v-col> -->
      <v-col md="7" class="my--" style="background-color: #f0f8ff">
        <div class="d-flex align-center">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">{{ item.barcode }}</h1>

          <!-- <v-chip small color="success">{{ item.status }} - {{ item.payment_mode }}</v-chip> -->
          <v-chip v-if="item.payment_mode && item.status == 'Paid'" class="white--text mr-2" small label
            :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Initial Paid - {{ item.payment_mode }}</v-chip>

          <v-chip v-if="item.status == 'Pending' && !item.bill" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'red' : 'red'">Initial Pending</v-chip>
          <v-chip v-else-if="item.status == 'Draft'" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'cyan' : 'cyan'">Draft</v-chip>
          <v-chip v-else-if="item.status == 'Pending' && item.bill" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'orange' : 'orange'">Final Pending</v-chip>
          <v-chip v-else-if="item.status == 'Initial Paid' && item.bill" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Initial Paid - {{ item.payment_mode }}</v-chip>
          <v-chip v-else-if="item.status == 'Initial Paid'" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Initial Paid - {{ item.payment_mode }}</v-chip>
          <v-chip v-else-if="item.status == 'Paid' && item.bill" class="white--text" small label
            :color="item.po_status == 'Issued' ? 'green' : 'green'">Final Paid </v-chip>
          <!-- <v-chip small color="info" v-if="item.bill" class="d-flex ">{{ item.bill }}</v-chip> -->

          <v-btn v-if="!checked.length && item.status == 'Pending' && !item.bill && item.status !== 'Draft'"
            :disabled="pageLoading" depressed tile class="mx-2 white--text" color="blue darken-4"
            v-on:click="recordPaymentDialog = true">
            Record Primary Payment
          </v-btn>
          <!-- {{ item.bill }} -->
          <v-btn v-if="!checked.length && !item.bill && item.status !== 'Draft'" :disabled="pageLoading" depressed tile
            class="mx-2 white--text" color="cyan"
            :to="{ name: 'bills-create', query: { t: new Date().getTime(), uuid: item.uuid } }">
            Convert To Bill
          </v-btn>

        </div>
      </v-col>
      <v-col md="5" class="text-right" style="background-color: #f0f8ff">
        <div class="d-flex align-center justify-content-end">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">Total:</h1>

          <h1 v-if="item && item.total" class="blue--text font-weight-bold mb-0">
            {{ formatMoney(item?.total) }}
          </h1>
          <v-btn v-if="!checked.length && item.status == 'Draft'" :disabled="pageLoading" depressed tile
            class="mx-2 white--text" color="blue darken-4" :to="{
              name: 'purchase-order-update',
              params: { id: item.uuid },
              query: { t: new Date().getTime() },
            }">
            <v-icon small>fas fa-edit</v-icon>
          </v-btn>

          <v-btn v-if="!checked.length && item.status == 'Draft'" :disabled="pageLoading" depressed tile
            class="mx-2 white--text" color="red lighten-1" v-on:click="deleteConfirm()">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>

          <template v-if="item.status == 'Draft'">
            <v-menu transition="slide-y-transition" bottom content-class="custom-menu-list" offset-y>

              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2 custom-bold-button white--text" color="cyan" v-bind="attrs" v-on="on">
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in customerMoreAction">
                  <v-list-item link v-on:click="approve(item.uuid)" :key="index">
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
          <v-btn v-if="item.status != 'Draft'" :disabled="pageLoading" depressed v-on:click="downloadPdf()" tile
            class="mx-2 white--text" color="red darken-4">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <router-link :to="{ path: '/purchase-order', query: { t: new Date().getTime(), page: this.$route.query.page } }">
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile>
              Back
            </v-btn></router-link>

        </div>
      </v-col>
      <v-col md="12" class="py-0" style="background-color: #f0f8ff">
        <v-row>
          <v-col md="4" class="pb-2 pt-0">
            <div class="pb-2">
              <b>Supplier :</b> <span class="text-h6 text-capitalize">{{ item.supplier }}</span>
            </div>
            <div v-if="item.bill">
              <b>Bill :</b> {{ item.bill }}
              <v-chip v-if="item.status == 'Paid' && item.bill" class="white--text" small outlined label
                :color="item.po_status == 'Issued' ? 'green' : 'green'">Final Paid</v-chip>
            </div>
            <div v-if="this.payment_items[0]?.parent_id?.barcode">
              <b>PV :</b> {{ this.payment_items[0]?.parent_id?.code }} {{ this.payment_items[0]?.parent_id?.barcode }}

            </div>
          </v-col>

          <v-col md="4" class="pb-2 pt-0">
            <div class="pb-2">
              <b>Ref Number
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="disabled" size="18">mdi-help-circle-outline</v-icon>
                  </template>
                  <span>Reference Number Printed on pdf</span>
                </v-tooltip>
                :</b>
              <span class="text-capitalize">{{ item.refNumber }}</span>
            </div>
            <div>
              <b>Receiving Warehouse : </b><span>{{ item.receivingAdd }}</span>
            </div>
          </v-col>

          <v-col md="4" class="pb-2 pt-0">
            <div class="pb-2"><b>Created by :</b> {{ item.user_name }}</div>
            <div><b>Created Date :</b> {{ formatedateTimes(item.added_at) }}</div>
          </v-col>
        </v-row>
      </v-col>
      <!-- left side -->
      <v-col v-if="false" md="3" class="pr-2">
        <v-card elevation="2" style="height: 100%">
          <v-card-item class="d-flex justify-start pt-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="pageLoading" depressed fab x-small tile class="rounded-circle mx-2"
                  style="cursor: pointer" @click="routeTo()" v-bind="attrs" v-on="on">
                  <v-icon class="pa-1">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="me-2 white--text" v-bind="attrs" v-on="on" small color="green">Received</v-chip>
              </template>
              <span>Received Status</span>
            </v-tooltip>
          </v-card-item>

          <v-card-item class="d-flex justify-center pt-3">
            <ImageTemplate src="https://upload.wikimedia.org/wikipedia/commons/4/47/Hamburger_%28black_bg%29.jpg"
              :maxWidth="150" :maxHeight="150" :aspectRatio="1" :contain="false" circle />
          </v-card-item>

          <v-card-item class="d-flex justify-center align-center">
            <v-card-title class="font-weight-bold">bthrust pvt ltd</v-card-title>
          </v-card-item>

          <v-card-item class="d-flex align-center justify-center pb-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="pageLoading" depressed fab x-small tile class="rounded-circle mx-2 white--text"
                  color="blue darken-4" :to="{
                    name: 'purchase-order-update',
                    params: { id: customerId },
                    query: { t: new Date().getTime() },
                  }" v-bind="attrs" v-on="on">
                  <v-icon class="pa-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="pageLoading" depressed fab x-small tile class="rounded-circle mx-2 white--text"
                  color="red lighten-1" v-on:click="deleteCustomerConfirm()" v-bind="attrs" v-on="on">
                  <v-icon class="pa-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-item>
          <v-divider class="mt-0"></v-divider>
          <v-card-item class="d-flex flex-column align-center" style="overflow-y: auto">
            <v-row class="basic-detail-row" style="width: 100%">
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Barcode</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">#PO0001</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Ref Number</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">101</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Delivery Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>08/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
            </v-row>

            <div class="py-5">
              <v-btn :disabled="pageLoading" depressed tile class="mx-2 white--text" color="blue darken-4" :to="{
                name: 'purchase-order-update',
                params: { id: itemId },
                query: { t: new Date().getTime() },
              }">
                Edit
              </v-btn>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col class="pt-0 pe-0" style="width: calc(100% - 320px); border-right: 1px solid #d8dbdd">
        <v-tabs fixed-tabs v-model="purchaseOrderTab" background-color="transparent" color="blue darken-4"
          class="customtabs">
          <v-tab style="width: 100%" v-for="(tab, index) in product_tabs" :key="`product_${tab.key}_${index}`"
            :href="`#${tab.key}`" icons-and-text>
            <v-icon :size="18" class="me-1">{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider class="mt-0"></v-divider>
        <!-- <v-card>
          <v-card-item> -->
        <v-tabs-items v-model="purchaseOrderTab" style="height: 65vh; overflow-y: auto">
          <template v-for="(tab, index) in product_tabs">
            <v-tab-item :value="tab.key" :key="tab.key + '_' + index">
              <component :is="tab.template" :parentdetails="data" :pdetails="datas" :key="rerenderKey" />
            </v-tab-item>
          </template>
        </v-tabs-items>
        <!-- </v-card-item>
        </v-card> -->
      </v-col>

      <v-col style="max-width: 320px" class="ps-0 pt-0">
        <v-card elevation="2" height="100%">
          <v-list class="py-0 text-end">
            <div class="px-3 pt-0 pb-3 d-flex align-center">
              <div class="my-0" v-if="!checked.length">
                <TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="supplier"
                  v-on:keyup="searchingIteam" placeholder="Search..." v-model="searchSupplier"
                  prepend-inner-icon="mdi-magnify">
                </TextInput>
              </div>
              <v-menu offset-y left v-if="checked.length">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="cyan" v-bind="attrs" v-on="on" depressed tile class="white--text ms-2 mt-3" height="34">
                    Bulk Action <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <template v-for="(item, index) in bulkitems">
                    <v-list-item v-if="checks == 1 && item.action != 'record'" class="border-b" link :key="index">
                      <v-list-item-icon class="my-2 me-2">
                        <v-icon color="blue darken-4" v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-on:click="updateMoreAction(item.action)">{{
                        item.title
                      }}</v-list-item-title>
                      <!-- <v-list-item-title v-else >{{ item.title }}</v-list-item-title> -->
                    </v-list-item>
                    <v-list-item v-else-if="checks == 0" class="border-b" link :key="index">
                      <v-list-item-icon class="my-2 me-2">
                        <v-icon color="blue darken-4" v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-on:click="updateMoreAction(item.action)">{{
                        item.title
                      }}</v-list-item-title>
                      <!-- <v-list-item-title v-else >{{ item.title }}</v-list-item-title> -->
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
              <!-- {{ showFilter }} -->
              <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false" v-model="showFilter">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn color="blue darken-4" depressed @click="showFilter = !showFilter" tile v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }" class="white--text ms-2 mt-3" height="34">
                        <v-icon>{{ showFilter ? "mdi-filter-remove" : "mdi-filter-plus" }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item class="flex-column">
                    <v-form ref="productAddForm" v-model.trim="formValid" lazy-validation
                      v-on:submit.stop.prevent="updateOrCreate">
                      <!-- <div class="pb-2">
                        <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="supplier"
                          placeholder="Search"
                          v-model="searchSupplier"
                          prepend-inner-icon="mdi-magnify"
                        >
                        </TextInput>
                      </div> -->
                      <div class="pb-2">
                        <AutoCompleteInput hide-details :disabled="pageLoading" :loading="pageLoading"
                          :items.sync="paymentTypeList" id="payment-type" outlined v-model="paymentType"
                          placeholder="Payment type" v-on:click:clear="getItem()">
                        </AutoCompleteInput>
                      </div>
                      <div class="pb-2">
                        <DatePicker clearable hide-details :disabled="pageLoading" :loading="pageLoading"
                          id="delivery-date" v-model="delivery_date" placeholder="Delivery Date"
                          v-on:click:clear="getItem()"></DatePicker>
                      </div>
                      <div class="text-right">
                        <!-- <v-btn :disabled="pageLoading" depressed tile class="" :close-on-content-click="false">
                          Close
                        </v-btn> -->
                        <v-btn color="blue darken-4" @click="fill" depressed tile class="white--text ms-2">
                          Filter
                        </v-btn>
                      </div>
                    </v-form>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-divider class="my-0"></v-divider>
            <!-- <v-list-item-group > -->


            <div class="puchase-listing" v-if="items.length">
              <v-list-item v-for="(item, i) in items" class="puchase-listing-item cursor-pointer py-1 px-2"
                @click="setCurrent(item.uuid)" :class="{ active: item.uuid === currentElement }" :key="i"
                style="border-bottom: 1px solid darkgrey">
                <v-checkbox class="mx-0" v-model="checked" :disabled="item.bill != null || item.po_status == 'Draft'"
                  :value="item.uuid" v-on:click="() => getPayments(checked)"></v-checkbox>


                <v-list-item-content link v-on:click="routeToDetail(item.uuid)">
                  <div class="">
                    <div>
                      <p class="mb-1 font-weight-bold black--text text-start">
                        {{ item?.supplier }}
                      </p>
                      <div class="d-flex align-center justify-space-between black--text">
                        <div class="d-flex">
                          <p class="mb-0 border-right pr-2 me-2">{{ item.purchase_order }} </p>
                          <span><v-icon size="15">mdi-calendar</v-icon>{{ formatedateTime(item.delivery_date) }}</span>
                        </div>
                        <p style="min-width: 82px; text-align: start" class="mb-0 font-weight-bold black--text">
                          {{ formatMoney(item.amount) }}
                        </p>
                      </div>
                      <div class="d-flex align-center justify-space-between black--text">
                        <div class="d-flex">
                          <p class="mb-0  pr-2 me-2 text-capitalize">{{ item.refNumber }} </p>

                        </div>


                      </div>
                      <div class="d-flex align-center justify-space-between black--text mt-1">
                        <v-chip v-if="item.po_status == 'Pending' && !item.bill" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'red' : 'red'">Initial Pending</v-chip>
                        <v-chip v-else-if="item.po_status == 'Draft'" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'orange' : 'cyan'">Draft</v-chip>
                        <v-chip v-else-if="item.po_status == 'Pending' && item.bill" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'orange' : 'orange'">Final Pending</v-chip>
                        <v-chip v-else-if="item.po_status == 'Initial Paid' && item.bill" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Initial Paid - {{ item.payment_mode
                          }}</v-chip>
                        <v-chip v-else-if="item.po_status == 'Initial Paid'" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Initial Paid - {{ item.payment_mode
                          }}</v-chip>
                        <v-chip v-else-if="item.po_status == 'Paid' && item.bill" class="white--text" small label
                          :color="item.po_status == 'Issued' ? 'green' : 'green'">Final Paid - {{ item.payment_mode
                          }}</v-chip>
                        <!-- <v-chip v-if="item.po_status=='Pending' && !item.bill" small outlined label color="success">Initial Pending</v-chip> -->
                        <p style="min-width: 82px; text-align: start" class="mb-0">
                          {{ item.bill }}
                        </p>
                      </div>
                      <div class="d-flex justify-content-end black--text">
                        <v-chip v-if="item.payment_two && item.bill" class="white--text" small
                          :color="item.po_status == 'Issued' ? 'green' : 'green'">Final Paid</v-chip>
                        <v-chip v-else-if="!item.payment_two && item.bill" class="white--text" small
                          :color="item.po_status == 'Issued' ? 'green' : 'orange'">Final Pending</v-chip>
                        <!-- <v-chip v-if="item.po_status=='Pending' && !item.bill" small outlined label color="success">Initial Pending</v-chip> -->
                      </div>
                    </div>
                    <div v-if="false">
                      <p class="mb-0 font-weight-bold black--text">
                        {{ formatMoney(item.amount) }}
                      </p>
                      <div class="d-flex black--text">
                        <p class="mb-0 border-right me-2">{{ item.bill }}</p>
                      </div>

                      <div class="d-flex black--text">
                        <v-chip v-if="item.payment_two && item.bill" class="white--text" small
                          :color="item.po_status == 'Issued' ? 'green' : 'indigo'">Final Paid</v-chip>
                        <v-chip v-else-if="!item.payment_two && item.bill" class="white--text" small
                          :color="item.po_status == 'Issued' ? 'green' : 'green'">Final Pending</v-chip>
                        <!-- <v-chip v-if="item.po_status=='Pending' && !item.bill" small outlined label color="success">Initial Pending</v-chip> -->
                      </div>

                      <!-- <v-chip
                        class="white--text"
                        small
                        :color="item.po_status == 'Issued' ? 'blue' : 'success'"
                        >{{ item.po_status }}</v-chip
                      > -->
                    </div>
                  </div>

                </v-list-item-content>
              </v-list-item>

            </div>
            <template v-else>
              <tr>
                <td colspan="9">
                  <p class="m-0 text-center">
                    <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                    Uhh... There are no data at the moment.
                  </p>
                </td>
              </tr>
            </template>
            <!-- </v-list-item-group> -->
          </v-list>
          <div class="d-flex align-center justify-content-end mt-2">

<h3 class="custom-header-blue-text m-0 text-truncate me-3 ">Total:</h3>

<h3 v-if="totalPayment" class="blue--text font-weight-bold mb-0">
  {{ formatMoney(totalPayment) }}
</h3>
</div>
        </v-card>
        
        <!-- {{ totalPayment }} -->
      </v-col>

    </v-row>
    <DeleteTemplate type="Purchase Order" :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted." :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint" v-on:close="deleteDialog = false" v-on:delete:success="goBack()"></DeleteTemplate>
    <RecordPaymentDialog :dialog="recordPaymentDialog" :bulkdata="bulk" :pdetails="single"
      @close="recordPaymentDialog = false"></RecordPaymentDialog>
  </v-sheet>
</template>

<script>
import { toSafeInteger, find, startsWith } from "lodash";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FIND_PO, GET_All, FIND_POS, FIND_POCHECK, CHECK_PO } from "@/core/lib/pos.lib";
import { formatMoney } from "accounting-js";
import moment from "moment-timezone";
import { EventBus } from "@/core/event-bus/event.bus";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { SET_ERROR } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import POLineItemsTab from "./POLineItemsTab";
import { PATCH, PUT } from "@/core/services/store/request.module";

//import PendingDetailTab from "./PendingDetailTab";
import AttactmentTab from "./AttactmentTab";
import PaymentsTab from "./PaymentsTab";
import LogTab from "./LogTab";
import RecordPaymentDialog from "./RecordPaymentDialog";
import { SET_PO } from "@/core/services/store/listing.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "item-detail",
  title: "Detail Item",
  data() {
    return {
      currentElement: 0,
      searchIteam: [],
      customerMoreAction: [
        {
          title: "Mark as Approve",
          icon: "mdi-check-all",
          action: "approve",
        },

      ],
      rerenderKey: Number(new Date()),
      recordPaymentDialog: false,
      pageLoading: true,
      totalAmount: [],
      deleteText: null,
      payment_items: [],
      formValid: false,
      deleteEndpoint: null,
      deleteDialog: false,
      images: [],
      checkedValues: [],
      checked: [],
      record: [],
      data: {},
      bulk: [],
      checks: 1,
      single: {},
      datas: {},
      datass: [],
      itemId: null,
      selectedItem: 0,
      checkbox: true,
      selectedRows: [],
      showFilter: false,
      delivery_date: "",
      searchSupplier: "",
      paymentType: null,
      bulkitems: [
        { title: "Record Primary Payment", icon: "mdi-check-all", action: "record" },
        { title: "Convert to Bill", icon: "mdi-check-all", action: "bill" },
        // { title: "Delete", icon: "mdi-check-all", action: "delete" },
      ],
      paymentTypeList: [
        {
          text: "Final Paid",
          value: "Paid",
          color: "",
        },
        {
          text: "Draft",
          value: "Draft",
          color: "",
        },
        {
          text: "Initial Paid",
          value: "Initial Paid",
          color: "",
        },
        {
          text: "Initial Pending",
          value: "Pending",
          color: "",
        },
        {
          text: "Final Pending",
          value: "final_pending",
          color: "",
        },
      ],
      supplierFilter: [
        {
          title: "Actions",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "#",
          status: true,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Details",
          status: false,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "blue darken-4",
          },
        },
        {
          title: "Supplier",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Contact Person",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
      ],
      items: [],
      purchaseOrderTab: null,
      product_tabs: [
        {
          key: "line-items",
          title: "LIne Items",
          icon: "mdi-view-list",
          template: POLineItemsTab,
        },
        {
          key: "details",
          title: "Details",
          icon: "mdi-information-outline",
          template: PurchaseOrderDetails,
        },

        // {
        //   key: "pending",
        //   title: "Pending",
        //   icon: "mdi-view-list",
        //   template: PendingDetailTab,
        // },
        {
          key: "attactments",
          title: "Attachments",
          icon: "mdi-file-multiple",
          template: "AttactmentTab",
        },
        {
          key: "logs",
          title: "Logs",
          icon: "mdi-history",
          template: "LogTab",
        },
        {
          key: "payment",
          title: "Payment",
          icon: "mdi-currency-usd",
          template: "PaymentsTab",
        },
      ],
      item: {
        barcode: null,
        name_en: null,
        name_ch: null,
        payment_mode: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
      purchase_order_details: [
        {
          name: "Code",
          value: "BT002",
        },
        {
          name: "Category",
          value: "Disposable",
        },
        {
          name: "Department Category",
          value: "Bar Alcoholic",
        },
        {
          name: "Primary (sales) UOM",
          value: "BTL (BTL)",
        },
        {
          name: "Secondary (purchase) UOM",
          value: "CTN (CTN)",
        },
        {
          name: "Unit Description (primary sales uom)",
          value: "250ml",
        },
      ],
      purchaseOrder: [
        {
          id: 2,
          barcode: "PO0001",
          supplier: "bthrust pvt ltd",
          suppliers: null,
          refNumber: "101",
          delivery_date: "08/09/2023",
          project: "",
          amountValue: "$ 4,750.00",
          amountStatus: "Paid",
          po_status: "Draft",
          paymentModes: "Cash",
          payment_voucher: "ABCD123",
        },
      ],

      rowuct: {
        name: "Pineapple juice",
      },
    };
  },
  methods: {
    goBackss() {
      this.$router.go(-1); // Go back to previous page
    },
    routeTo() {
      this.$router.push({
        name: "purchase-order",

        query: { t: new Date().getTime(), page: this.$route.query.page },
      });
    },
    downloadPdf() {
      this.pdf = this.$route.params.id;
      console.log(this.pdf, 'pdf')
      let downloadURL = process.env.VUE_APP_API_URL + `purchase-order-pdf/${this.pdf}`;
      console.log(downloadURL, 'downloadURL')
      downloadURL = new URL(downloadURL);
      window.open(downloadURL, "_blank");


    },
    routeToDetail(id) {
      this.$router.push({
        name: "purchase-order-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
      this.getItem();
    },
    approve(id) {
      this.$store
        .dispatch(PUT, {
          url: `purchase-order-approve`,
          data: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data)
          this.$router.replace({
            name: "purchase-order",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Purchase Order Approved Successfully." },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

    },
    searchingIteam() {
      // let data = this.searchIteam.filter((item) =>
      //   startsWith(item.supplier.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
      // );
      let data = this.searchIteam.filter((item) => {
        return startsWith(item.supplier.toLowerCase(), this.searchSupplier.toLowerCase(), 0) || startsWith(item.purchase_order.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
      });
      this.items = data;
      console.log(data, 'data')
    },

    fill() {

      if (
        !this.paymentType
      ) {
        this.$store.commit(SET_ERROR, [
          { model: true, message: "The payment type field is required" },
        ]);
        return false;
      }
      this.date = this.delivery_date;
      this.po_id = this.$route.params.id;
      this.status = this.paymentType;

      this.$store
        .dispatch(PATCH, {
          url: `purchase-order/supplier-filter`,
          data: {
            delivery_date: this.date,
            status: this.status,
            id: this.po_id,
          },
        })
        .then((data) => {
          this.items = data.data;
          this.showFilter = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;

        });
    },
    async ttsupplier(item) {
      // this.checkbox = this.checked;

      const pos = await CHECK_PO(item);
      this.checks = pos;
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "record":
          // updateMoreAction
          _this.recordPaymentDialog = true;
          break;
        case "bill":
          _this.$router.push({
            name: "bills-create",
            query: { t: new Date().getTime() },
          });

          break;
        case "delete":
          _this.bulkdeleteConfirm(this.bulk);

        // _this.$router.push({
        //   name: "bills-create",
        //   query: { t: new Date().getTime() },
        // });
      }
    },
    setCurrent(i) {
      this.currentElement = i;
    },
    // getTotal(){
    //       this.product = this.datass

    // 		let sum = 0;
    //     console.log(this.pdetails)
    // 		this.product?.forEach((element) => {

    // 			sum = sum + Number(element.total);
    // 		});
    //     console.log(sum)
    // 		this.total= Number(sum);
    //     return this.total;
    // 		//  this.getDiscount();

    //     },
    //     getTotals(){
    //       this.product = this.datas

    // 		let sum = 0;

    // 		this.product?.forEach((element) => {

    // 			sum = sum + Number(element.total);
    // 		});
    //     console.log(sum)
    // 		this.totals= Number(sum);
    //     return this.totals;
    // 		//  this.getDiscount();

    //     },

    async getPayments(item) {
      this.purchase = item;

      const pos = await FIND_POCHECK(item);
      this.check = pos;
      console.log(this.check, 'this.check')
      this.totalAmount = this.check;

      let sum = 0;

      this.totalAmount?.forEach((element) => {
        sum = sum + Number(element.total);
      });

      this.totalPayment = Number(sum);
      console.log(this.totalPayment, 'totalPayment')
      this.bulk = pos;
      this.single = {};
      this.key = this.checked;
      this.ttsupplier(this.checked);
      this.$store.dispatch(SET_PO, this.key);

      EventBus.$emit("key", this.key);
    },

    /*  async getPayments(item) {
      this.purchase = item;
      const pos = await FIND_POCHECK(item);
      this.datass = pos;
    }, */
    async getPayment(item) {
      this.purchase = item;
      const po = await FIND_POS(this.purchase);
      this.item = {
        barcode: po?.barcode,
        uuid: po?.uuid,
        bill: po?.bill,
        name_ch: po?.name_ch,
        payment_twos: po?.payment_two,
        payment_mode: po?.payment_mode,

        supplier: po?.supplier?.company_name,
        delivery_date: po?.delivery_date,
        refNumber: po?.refNumber,
        receivingAdd: po?.receivingAdd,
        total: po?.total,
        status: po?.status,
        user_name: po?.added_by?.display_name,
        added_at: po?.added_at,
      };
      this.currentElement = this.item?.uuid;
      this.single = po;
      this.datas = po;
      this.data = po;

    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    deleteConfirm() {
      this.deleteText = this.item.barcode;
      this.deleteEndpoint = `purchase-order/${this.$route.params.id}`;
      this.deleteDialog = true;
    },
    bulkdeleteConfirm() {
      let deletBarcode = this.bulk.map((item) => item.barcode);

      this.deleteText = deletBarcode.join(", ");
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      if (this.item.name_en) {
        return this.item.name_en;
      }
      return "Purchase Order";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },

    async getItem() {
      try {
        const item = await FIND_PO(this.$route.params.id);

        this.data = item;
        this.checkedValues.push(item.uuid);
        this.getPayment(this.$route.params.id);

        this.currentElement = item.uuid;
        this.getPo(item.supplier.uuid);
        //  this.getTotal();

        this.item = {
          barcode: item.barcode,
          uuid: item.uuid,
          payment_twos: item?.payment_two,
          payment_mode: item?.payment_mode,
          bill: item?.bill,
          name_ch: item?.name_ch,
          supplier:
            item && item?.supplier && item?.supplier?.company_name
              ? item?.supplier?.company_name
              : null,
          suppliers: item?.supplier?.company_name,
          delivery_date: item?.delivery_date,
          refNumber: item?.refNumber,
          receivingAdd: item?.receivingAdd,
          total: item?.total,
          status: item?.status,
          user_name:
            item && item.added_by && item?.added_by?.display_name
              ? item?.added_by?.display_name
              : null,
          added_at: item?.added_at,
        };
        this.line_items = item?.order_items;
        this.payment_items = item?.record_payment;


        this.images = item.images;

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.barcode, disabled: true },
        ]);
        this.rerenderKey = Number(new Date());
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.showFilter = false;
      }
    },
    async getPo() {
      try {
        this.po_id = this.$route.params.id;
        const item1 = await GET_All(this.po_id);
        this.items = item1.data;
        this.searchIteam = item1.data;
        // this.record = [
        //   {
        //     item
        //   }
        // ];

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item1.barcode, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },

  // getTotal(){
  //         this.product = this.pdetails

  // 			let sum = 0;
  //       console.log(this.pdetails)
  // 			this.product?.forEach((element) => {

  // 				sum = sum + Number(element.total);
  // 			});
  //       console.log(sum)
  // 			this.total= Number(sum);
  // 			//  this.getDiscount();
  // 			return this.total;

  //       },
  // computed: {
  //   purchaseOrderTab: {
  //     set(val) {
  //       let query = { ...this.$route.query };
  //       query.tab = val;
  //       query.t = new Date().getTime();
  //       if (val != this.purchaseOrderTab) {
  //         this.$router.replace({ query });
  //       }
  //     },
  //     get() {
  //       return this.$route.query.tab || "line-items";
  //     },
  //   },
  // },
  mounted() {
    // this.ttsupplier();

    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);
    // alert(this.$route.params.id)
    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.getItem();

      // this.getPo();
    } else {
      this.goBack();
    }
  },

  components: {
    DeleteTemplate,
    ImageTemplate,
    ValueTemplate,
    PurchaseOrderDetails,
    POLineItemsTab,
    AttactmentTab,
    LogTab,
    DatePicker,
    TextInput,
    RecordPaymentDialog,
    PaymentsTab,
    AutoCompleteInput
  },
  computed: {},
};
</script>
<style scoped>
.header-title {
  position: sticky;
  width: 85%;
  background-color: white;
  z-index: 99;
  top: 65px;
  border-top: 8px solid #f7941e !important;
}

.customtabs .v-tabs .v-tab:not(.v-tab--active) {
  background-color: #fff !important;
}

.customtabs.v-tabs .v-slide-group__wrapper .v-tab {
  font-size: 14px !important;
  font-weight: 600 !important;
}

/* .po-line-items tbody tr:nth-child(even) {
  background-color: #e3eff9 !important;
} */
.puchase-listing .puchase-listing-item:nth-child(odd) {
  background-color: #f2f6fa !important;
}

.puchase-listing .puchase-listing-item.active.cursor-pointer.v-list-item.theme--light {
  background-color: #9fcbf3 !important;
  /* opacity: 0.3!important; */
}
</style>
